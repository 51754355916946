import React, { useState, useEffect } from 'react'
import Button from "../shared/Button"
import View from "./View"

const labels = ["Información General", "Tipo de Servicio", "Agendamiento", "Solicitud"]

export default function Form(props) {
  console.log("PROPS=>", props)
  const [step, setStep] = useState(1)

  useEffect(() => {
    window.scrollTo({ top: 100, behavior: 'smooth' })
    if (!props.data.coverage_city_id) return
    if (props.data.coverage_city_id) setStep(2)
    if (props.data.manteinance_products[0].id) setStep(3)
    if (props.data.time_frame) setStep(4)
  }, [])

  const nextStep = () => {
    window.scrollTo({ top: 100, behavior: 'smooth' })
    setStep(e => e + 1)
  }
  const common = { ...props, nextStep }

  return (
    <div id="FormPost">
      <Dots {...{step, labels, setStep}} />
      <div className="container-form">
        <StepTitle step={step} />
        {step === 1 && <Step1 {...common} />}
        {step === 2 && <Step2 {...common} />}
        {step === 3 && <Step3 {...common} />}
        {step === 4 && <Step4 {...common} />}
      </div>
    </div>
  )
}

function Dots({ step, labels, setStep }) {
  return (
    <div id="Dots">
      <div className="container-lined">
        {labels.map((e, i) =>
          <div key={"dots" + i} onClick={()=>{
            if(i + 2 <= step) setStep(i+1)
          }} className={`dot-group ${i+2 <= step && 'clickable'}`} style={{ left: i * 33 + "%" }}>
            <div className={`dot dot-${i + 2 <= step ? "complete" : step == i + 1 ? "active" : ""}`} />
            <div className="dot-label">{e}</div>
          </div>
        )}
      </div>
    </div>
  )
}


const Step1 = ({ data, handleService, nextStep, back, cities, api }) => {
  const [option, setOption] = useState(0)
  const [loading, setLoading] = useState(false)

  const save = () => {
    setLoading(true)
    api.updateService(data.id, data, (response) => {
      console.log("loading false")
      setLoading(false)
      if (response.errors) return
      nextStep()
    })
  }


  return (<div className="Step">
    <p>Información del contacto de la persona que recibe el servicio</p>
    <div className="step1-buttons">
      <div className="maxW350">
        <Radial option={option} id={1} listener={() => setOption(1)} label="Yo soy la misma persona que recibe el servicio" />
        {option === 1 && <div className="contForm">
          <select name="coverage_city_id" value={data.coverage_city_id || ""} placeholder="Ciudad" onChange={handleService}>
            <option value="">* Selecciona una ciudad</option>
            {cities.map(e => <option key={e.id + "opt"} value={e.id}>{e.city}</option>)}
          </select>
          <input type="text" name="address" value={data.address || ""} onChange={handleService} placeholder="* Escribe la dirección del servicio" />
          <input type="text" name="name" value={data.name} readOnly placeholder="* Escribe tu nombre" />
          <input type="text" name="phone" value={data.phone} readOnly placeholder="* Escribe tu teléfono" />
        </div>}
        <Radial option={option} id={2} listener={() => setOption(2)} label="Es otra persona la que recibe el servicio" />
        {option === 2 && <div className="contForm">
          <select name="coverage_city_id" value={data.coverage_city_id || ""} placeholder="Ciudad" onChange={handleService}>
            <option value="">* Selecciona una ciudad</option>
            {cities.map(e => <option key={e.id + "opt"} value={e.id}>{e.city}</option>)}
          </select>
          <input type="text" name="address" value={data.address || ""} onChange={handleService} placeholder="* Escribe la dirección del servicio" />
          <input type="text" name="reception_name" value={data.reception_name || ""} onChange={handleService} placeholder="* Nombre de quien recibe el servicio" />
          <input type="text" name="reception_phone" value={data.reception_phone || ""} onChange={handleService} placeholder="* Teléfono de quien recibe el servicio" />
        </div>}
      </div>
    </div>
    <div className="explain">* Estos campos son obligatorios</div>
    {loading && <div className="loading">Cargando...</div>}
    <Button label="ATRÁS" listener={back} theme="light-blue" />
    {data.coverage_city_id && data.address ?
      <Button label="CONTINUAR" listener={save} />
      :
      <Button label="CONTINUAR" theme="gray" listener={()=>{}} />
    }
  </div>)
}

const Step2 = (props) => {
  const [equipos, setEquipos] = useState(props.data.manteinance_products)
  const [loading, setLoading] = useState(false)

  const handleEquipo = (index, e) => {
    let newData = [...equipos]
    newData[index][e.target.name] = e.target.value
    console.log("new data: ", newData)
    setEquipos(newData)
  }

  const save = () => {
    setLoading(true)
    props.api.saveMaintenanceProducts(props.data.id, equipos, (response) => {
      setLoading(false)
      if (!response.errors) {
        props.handleService({ target: "maintenance_products", value: equipos })
        props.nextStep()
      } else {
        console.log(response)
      }
    })
  }

  const validate = () =>{
    let valid = true
    for(const equipo of equipos){
      if(!equipo.product_id || !equipo.maintenance_service_id || !equipo.service_type_id || !equipo.quantity){valid = false; break}
    }
    return valid
  }

  return (<div className="Step">
    {equipos.map((info, index) => <Equipo key={index + "equipo"} {...{ index, info, handleEquipo }} {...props} />)}
    <div className="Step">
      <Button label="+ OTRO EQUIPO" listener={() => { setEquipos(e => [...e, { ...props.product, service_id: props.data.id }]) }} theme="light-blue" />
    </div>
    {loading && <div className="loading">Cargando...</div>}
    {validate() && <Button label="CONTINUAR" listener={save} />}
  </div>)
}

const Step3 = ({ data, handleService, nextStep, api }) => {
  const [option, setOption] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (option !== 0) handleService({ target: { name: "time_frame", value: option === 1 ? "Mañana" : (option === 2 ? "Tarde" : "Cualquiera") } })
  }, [option])

  const save = () => {
    setLoading(true)
    api.updateService(data.id, data, (response) => {
      setLoading(false)
      if (response.errors) return
      nextStep()
    })
  }
  return (<div className="Step">
    <div className="maxW800">
      <div className="step-flex contFormBig">
        <div className="opt">
          <p>Selecciona la Franja</p>
          <div className="maxW150">
            <Radial option={option} id={1} listener={() => setOption(1)} label="Mañana" />
            <Radial option={option} id={2} listener={() => setOption(2)} label="Tarde" />
            <Radial option={option} id={3} listener={() => setOption(3)} label="Cualquiera" />
          </div>
        </div>
        <div className="opt2">
          <p>Selecciona una Semana</p>
          <Calendar handleService={handleService} />
        </div>
      </div>
    </div>
    {loading && <div className="loading">Cargando...</div>}
    <Button label="CONTINUAR" listener={save} />
  </div>)
}

const Step4 = ({ data, api }) => {
  const [loading, setLoading] = useState(false)

  return (
    <div>
      <View id={data.id} />
      {loading && <div className="loading">Cargando...</div>}
      <div className="mt60 maxW150">
        <Button label="SOLICITAR" listener={() => {
          setLoading(true)
          api.publishService(data.id, data, (r) => {
            if (!r.errors) window.location = "/services/" + data.phone + "-" + data.id + "/track"
          })
        }} />
      </div>
    </div >
  )
}

const Equipo = ({ index, info, handleEquipo, categories, mservices, serviceTypes, brands, api }) => {
  const [select, setSelect] = useState(false)
  const [references, setReferences] = useState([])
  const [equipo, setEquipo] = useState(info)
  const [catBrands, setCatBrands] = useState(brands)

  useEffect(() => {
    setSelect(false)
    api.getProducts(equipo.filter_id, equipo.brand_id, (r) => {
      setReferences(r)
      setSelect(true)
    })
  }, [equipo.filter_id, equipo.brand_id])

  const update = (e) => {
    console.log("update", index, e)
    handleEquipo(index, e)
    setEquipo(prev => { return { ...prev, [e.target.name]: e.target.value } })
  }

  const filter_update = (e) =>{
    api.getBrands(e.target.value, (br) => setCatBrands(br))
    update(e)
  }

  return (
    <>
      <div className="step-flex">
        <div className="opt">
          <StepTitle step="A" label="Equipo" type="sm" />
          <div className="container-form">
            <select name="filter_id" value={equipo.filter_id || ""} onChange={filter_update}>
              <option value="">Filtra por categoría</option>
              {categories.map(e => <option key={index + "cat" + e.name + e.id} value={e.id}>{e.name}</option>)}
            </select>
            <select name="brand_id" value={equipo.brand_id || ""} onChange={update} >
              <option value="">Filtra por marca</option>
              {catBrands.map(e => <option key={index + "brand" + e.name + e.id} value={e.id}>{e.name}</option>)}
            </select>
            {select && <Select2
              id={`product-${index}`} placeholder={`* Selecciona un equipo (${references.length})`}
              value={equipo.product_id || ""}
              data={references.map(e => {return {id: e.id, text: `${e.reference} - ${e.name}` }})}
              update={update}
            />}
            <input min="0" name="quantity" value={equipo.quantity || ""} onChange={update} type="number" placeholder="* Ingresa la cantidad de equipos" />
            <textarea name="description" value={equipo.description || ""} onChange={update} placeholder="Describe brevemente la falla" />
          </div>
        </div>
        <div className="opt">
          <StepTitle step="B" label="Tipo de Servicio" type="sm" />
          <div className="container-form">
            <select name="maintenance_service_id" value={equipo.maintenance_service_id || ""} onChange={update}>
              <option value="" disabled>* Selecciona el tipo de servicio</option>
              {mservices.map(e => <option key={index + "ms" + e.name + e.id} value={e.id}>{e.name}</option>)}
            </select>
          </div>
          <StepTitle step="C" label="Alcance del Servicio" type="sm" />
          <div className="container-form">
            <select name="service_type_id" value={equipo.service_type_id || ""} onChange={update}>
              <option value="" disabled>* Selecciona el tipo de alcance</option>
              {serviceTypes.map(e => <option key={index + "st" + e.name + e.id} value={e.id}>{e.name}</option>)}
            </select>
          </div>
          
        </div>
      </div>
      <hr />
    </>
  )
}


const StepTitle = ({ step, label, type = "" }) =>
  <div className={`StepTitle ${type}`}>
    <div className="title-number">{step}</div>
    <div className="title-label">{label || labels[step - 1]}</div>
  </div>

const Radial = ({ id, option, listener, label }) =>
  <div className="Radial" onClick={listener}>
    <div className={`radial ${option == id}`} />
    <div className="radial-label">{label}</div>
  </div>


const Calendar = ({ handleService }) => {
  const [selected, setSelected] = useState(0)
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  //to onth cycles. After 11 is next year
  const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre", "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
  const [month, setMonth] = useState(currentMonth)
  const [weeks, setWeeks] = useState(window.getWeeksStartAndEndInMonth(month, currentYear, "monday"))

  useEffect(() => {
    let year = currentYear
    if (month > 11 && year === currentDate.getFullYear()) { year++; setCurrentYear(year) }
    setWeeks(window.getWeeksStartAndEndInMonth(month, year, "monday"))
    setSelected(0)
  }, [month])

  useEffect(() => {
    console.log("selected week", weeks[selected].start + "-" + weeks[selected].end)
    handleService({ target: { name: "week", value: weeks[selected].start + " al " + weeks[selected].end + " de " + monthNames[month] + " del " + currentYear } })
  }, [selected])

  return (
    <div className="calendar">
      <div className="year">{currentYear}</div>
      <div className="month">
        {currentMonth < month ? <span onClick={() => setMonth(e => e - 1)}> {"<"} </span> : <span> - </span>}
        {monthNames[month]}
        {month < currentMonth + 6 ? <span onClick={() => setMonth(e => e + 1)}> {">"} </span> : <span> - </span>}
      </div>
      <table>
        <tbody>
          <tr className="week days">
            <td >L</td><td >M</td><td >I</td><td >J</td><td >V</td>
          </tr>
          {weeks.map((week, i) =>
            <tr key={"week" + i} onClick={() => setSelected(i)} className={`week ${i === selected}`}>
              <td>{week.startDay <= 0 && week.start}</td>
              <td>{week.startDay <= 1 && week.start + 1 - week.startDay}</td>
              <td>{week.startDay <= 2 && week.start + 2 - week.startDay}</td>
              <td>{week.startDay <= 3 && week.start + 3 - week.startDay}</td>
              <td>{week.startDay <= 4 && week.start + 4 - week.startDay}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}


const Select2 = props =>{
  useEffect(()=>{
    new TomSelect(`#${props.id}`)
  }, [])

  return(
    <div id={`${props.id}-container`}>
      <select id={props.id} name="product_id" value={props.value} onChange={props.update}>
        <option value="" disabled>{props.placeholder}</option>
        {props.data.map((e, i) => <option key={props.id + e.text+i} value={e.id}>{e.text}</option>)}
      </select>
    </div>
  )
}