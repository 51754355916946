import React, { useState } from 'react'
import Button from "../shared/Button"

export default function Rastrear({ back }) {
  const [inp, setInp] = useState("")
  return (
    <div>
      <h1>Número de Solicitud</h1>
      <input className="input-form mt60" type="text" value={inp} onChange={e => {
        setInp(e.target.value)
      }} name="service_number" placeholder="Ingresa el número de servicio" />
      <div className="flex justify-center mt60">
        <Button theme="light-blue" listener={back} label="Atrás" />
        <Button listener={() => {
          window.location = "/services/" + inp + "/track"
        }} label="Enviar" />
      </div>
    </div>
  )
}
