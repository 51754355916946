import React from 'react'

export default function Button({label, listener, theme = "blue", width}) {
  const style = { backgroundColor: `var(--${theme})` }
  if (width) style.width = width
  return (
    <div onClick={listener} className="btn btn-primary btn-lg btn-circle" 
      style={style}>{label}</div>
  )
}

