import React, { useEffect, useState } from 'react'
import api from "../api"

export default function View({ id }) {
  const [data, setData] = useState(false)
  useEffect(() => {
    api.getFullService(id, (r) => {
      console.log(r)
      setData(r)
    })
  }, [])
  if (!data) return <div className="loading">Cargando...</div>
  return (
    <div className="Step Details">
      <div className="title-gray">RESUMEN SOLICITUD #:<span>{data.phone}-{data.id}</span></div>
      <div className="title-gray">Detalles del Servicio</div>
      <div className="body">Te enviaremos un mail con la confirmación del servicio</div>
      <hr />
      <div className="title-blue">Datos de quien solicita</div>
      <div className="body">{data.name}</div>
      <div className="body">{data.phone}</div>
      <div className="body">{data.email}</div>
      <hr />
      <div className="title-blue">Datos de quien recibe el servicio</div>
      <div className="body">{data.reception_name || data.name}</div>
      <div className="body">{data.reception_phone || data.phone}</div>
      <div className="body">{data.address} - {data.coverage_city}</div>
      <hr />
      <div className="body"><span className="blue">Fechas posibles de agendamiento: </span>{data.week}</div>
      <div className="body"><span className="blue">Franja: </span>{data.time_frame}</div>
      <hr />
      <table>
        <tbody>
          {data.manteinance_products.map((mp, i) =>
            <tr key={"mp" + i}>
              <td className="blue number">{i + 1}.</td>
              <td><span className="blue">Equipo</span><br /><br />{mp.quantity}x {mp.product.reference}<br />{mp.product.name}</td>
              <td><span className="blue">Mantenimiento</span><br /><br />{mp.maintenance_service.name}</td>
              <td><span className="blue">Solicitud</span><br /><br />{mp.service_type.name}</td>
            </tr>
          )}

        </tbody>
      </table>
      <hr />
      <div className="title-blue centered">VALOR DEL SERVICIO DESDE:<span className="gray">${data.cost.toLocaleString()}*</span></div>
      <div className="explain">* El valor contempla programar <strong>{data.visits} visita(s)</strong> por la cantidad y tipo de equipos seleccionados, según la cobertura.</div>
      <div className="explain">* El valor puede cambiar dependiendo del gasto de transporte</div>
    </div>
  )
}
