export default {
	//api
	getFullService: (id, listener) => call("GET", "services/" + id, {}, listener),
	createService: (service, listener) => call("POST", "services", { service }, listener),
	updateService: (id, service, listener) => call("PUT", "services/" + id, { service }, listener),
	publishService: (id, service, listener) => call("PUT", "services/" + id + "/publish", { service }, listener),
	getProducts: (filter_id, brand_id, listener) => call("GET", "services/products/get", { filter_id, brand_id }, listener),
	getBrands: (subcat, listener) => call("GET", "services/brands/"+subcat, {}, listener),
	saveMaintenanceProducts: (service_id, products, listener) => call("POST", "services/products/add", { service_id, products: JSON.stringify(products) }, listener)
}


const setParams = function (data, get) {
	if (!get) data = { ...data, authenticity_token: document.querySelector('meta[name="csrf-token"]').content }
	var formData = get ? {} : new FormData();
	var checkNested = function (fD, key, value) {
		const insertField = (k, v) => get ? fD[k] = v : fD.append(k, v)

		if (typeof value != "object") return insertField(key, value)
		if (Array.isArray(value)) return insertField(key, value.join(","))
		if (key === "images" && !get) { for (let k in value) fD.append(k, value[k], value[k].name); return }
		// console.log(key, value)
		if (key === "file" && !get) { fD.append(key, value, value.name); return }
		for (var obj in value) checkNested(fD, `${key}[${obj}]`, value[obj]);
	}
	for (var obj in data) checkNested(formData, obj, data[obj])
	return formData;
}

const setDefaults = function (data = {}, defaults = {}) { for (var key in defaults) data[key] = defaults[key]; return data; }

const call = function (method, services, data, listener) {
	const formData = setParams(data, method === "GET")
	let url = window.location.origin
	let service = `/${services}`
	console.log(method, service, formData)
	//Set Headers
	var myHeaders = new Headers();
	myHeaders.append("Accept", "application/json");

	var miInit = { method: method, headers: myHeaders, mode: 'cors', cache: 'default' };
	//Include formData in body if post or put
	if (formData && (method === "POST" || method === "PUT" || method === "DELETE")) {
		//console.log('miInit =>', miInit); 
		formData.append("noEmpty", "true") //avoid multipart errors
		miInit.body = formData;
	}
	//Call
	function middlewareListener(data) {
		if (listener != null && listener !== undefined) { listener(data) } else { console.log("Middleware data:", data) }
	}
	var built = new URL(url + service)
	if (method === "GET") Object.keys(formData).forEach(key => built.searchParams.append(key, formData[key]))
	const controller = new AbortController()

	fetch(built, { ...miInit, signal: controller.signal })
		.then(response => response.json()).then((data) => middlewareListener(data))
		.catch(err => console.log("Api error:", err))

	return controller
}
