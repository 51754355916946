import React, { useState, useEffect } from 'react'
import Button from "../shared/Button"
import Form from "./Form"
import api from "../api"

export default function Solicitar(props) {
  const [view, setView] = useState(0)
  const [data, setData] = useState(props.service)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.currentUser) handleService({ target: "email", value: props.currentUser.email })
  }, [])

  const handleService = e => {
    setData(p => { return { ...p, [e.target.name]: e.target.value } })
  }

  const saveService = () => {
    if (!data.name || !data.phone || !data.email || !data.company) return
    console.log("saving service")
    setLoading(true)
    api.createService(data, (response) => {
      setLoading(false)
      if (!response.errors) {
        setData(response)
        setView(1)
      }
    })

  }
  return (
    <div>
      {view === 0 &&
        <>
          <h1>Información de quien solicita el servicio</h1>
          <input className="input-form mt60" type="text" value={data.name || ""} onChange={handleService} name="name" placeholder="* Nombre" />
          <input className="input-form" type="text" name="phone" value={data.phone || ""} onChange={handleService} placeholder="* Teléfono" />
          <input className="input-form" type="email" name="email" value={data.email || ""} onChange={handleService} placeholder="* Correo" />
          <input className="input-form" type="text" name="company" value={data.company || ""} onChange={handleService} placeholder="* Escribe tu empresa" />
          {loading && <div className="loading">Cargando...</div>}
          <div className="mt60">
            <div><Button listener={saveService} width={300} theme={data.name && data.phone && data.email && data.company ? "blue" : "gray"} label="CONTINUAR" /></div>
            <div className="mt20"><a href="/clientes/sign_in"><Button theme="light-blue" label="INICIAR SESIÓN" /></a></div>
            <div className="mt20"><Button theme="light-blue" listener={props.back} label="ATRÁS" /></div>
          </div>
        </>
      }
      {view === 1 && <Form {...props} {...{ handleService, data, api }} back={() => setView(0)} />}
    </div>
  )
}




window.getWeeksStartAndEndInMonth = function (month, year, _start = "monday") {
  let weeks = [],
    firstDate = new Date(year, month, 1),
    lastDate = new Date(year, month + 1, 0),
    numDays = lastDate.getDate();
  let start = 1;
  let end = 7 - firstDate.getDay();
  if (_start == 'monday') {
    if (firstDate.getDay() === 0) {
      end = 1;
    } else {
      end = 7 - firstDate.getDay() + 1;
    }
  }
  while (start <= numDays) {
    console.log(start, end, year, month)
    var businessWeekEnd = end - 2
    if (businessWeekEnd > 0) {
      if (businessWeekEnd > start) {
        weeks.push({ start: start, startDay: new Date(year, month, start).getDay() - 1, end: businessWeekEnd });
      }
      else {
        //Check for last day else end date is within 5 days of the week.
        weeks.push({ start: start, end: end });
      }
    }
    start = end + 1;
    end = end + 7;
    end = start === 1 && end === 8 ? 1 : end;
    if (end > numDays) {
      end = numDays;
    }
  }

  return weeks;
}

//console.table(window.getWeeksStartAndEndInMonth(0, 2021, 'monday'));