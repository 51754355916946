import { hot } from "react-hot-loader/root"
import React, { useState } from 'react'
import Rastrear from "./PostVenta/Rastrear"
import Solicitar from "./PostVenta/Solicitar"
import Button from "./shared/Button"

// const hot = (f) => f
export default hot((props) => {
  const [view, setView] = useState(0)
  return (
    <div id="PostVentaReact">
      {view == 0 &&
        <div>
          <h1>Selecciona una opción con la que podamos ayudarte</h1>
          <div className="flex justify-center mt60">
            <Button listener={() => setView(1)} label="SOLICITAR UN SERVICIO" />
            <Button listener={() => setView(2)} label="RASTREAR UN SERVICIO" />
          </div>
        </div>
      }
      {view == 1 && <Solicitar back={() => setView(0)} {...props} />}
      {view == 2 && <Rastrear back={() => setView(0)} {...props} />}
    </div>
  )
})
